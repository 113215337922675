import isEmpty from 'lodash/isEmpty'
import { GA_EVENT_LABEL, GA_EVENT_NAME_TYPE } from '@/utils/constants/trackGA'
import { SupportedCountries } from '@/types/commons/constants'

export const trackGAEvent = (
  eventName: GA_EVENT_NAME_TYPE,
  variables: Record<string, string | object> = {}
) => {
  const gaEvents = {
    event: eventName,
    ...variables,
  }

  if (window['dataLayer'] && !isEmpty(gaEvents)) {
    window['dataLayer'].push(gaEvents)
  }
}

export const getGAEventActionStepForm = ({
  step,
  country,
}: {
  step: number | string
  country: SupportedCountries
}) => {
  switch (step) {
    case 1:
      return `1st_step`
    case '1a':
      return `1a_step_${country}`
    case 2:
      return `2nd_step_${country}`
    case 3:
      return `3rd_step_${country}`
    case 4:
      return `4th_step_${country}`
    case 5:
      return `5th_step_${country}`
    case 6:
      return `6th_step_${country}`
    case 7:
      return `7th_step_${country}`
    default:
      return ''
  }
}

export const getGAEventCategoryDeliveryType = ({
  isTidValid,
  senderCountry,
}: {
  isTidValid: boolean
  senderCountry: SupportedCountries
}) => {
  switch (senderCountry) {
    case 'sg':
      return `delivery_c2c_${isTidValid ? 'v2' : 'v1'}_sg`
    default:
      return ''
  }
}

export const getGAEventLabelDefaultDomestic = ({
  senderCountry,
}: {
  senderCountry: SupportedCountries
}) => {
  switch (senderCountry) {
    case 'sg':
      return GA_EVENT_LABEL.DEFAULT_DOMESTIC_SG
    default:
      return ''
  }
}

export const getGAEventLabelType = ({
  senderCountry,
  recipientCountry,
  isInternational,
}: {
  senderCountry: SupportedCountries
  recipientCountry: SupportedCountries
  isInternational: boolean
}) => {
  switch (senderCountry) {
    case 'sg':
      return isInternational
        ? `international_sg_${recipientCountry}`
        : `domestic_sg`
    default:
      return ''
  }
}

export const getGAEventInfo = ({
  c2cId,
  tid,
  promoCode,
}: {
  c2cId?: string
  tid?: string
  promoCode?: string
}) => {
  return [c2cId, tid, promoCode].filter(Boolean).join(',')
}
